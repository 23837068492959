export const Meatball = ({ color = '#02AD85' }) => {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill={color}
		>
			<g>
				<path d='M2.3,8a2.3,2.3,0,1,0,2.29,2.3A2.3,2.3,0,0,0,2.3,8Z' />
				<path d='M10.17,8a2.3,2.3,0,1,0,2.3,2.3A2.3,2.3,0,0,0,10.17,8Z' />
				<path d='M17.72,8A2.3,2.3,0,1,0,20,10.3,2.3,2.3,0,0,0,17.72,8Z' />
			</g>
		</svg>
	)
}
